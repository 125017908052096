/*
 * @Auth: linjituan
 * @Date: 2021-12-27 10:21:40
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-27 10:27:11
 */

export default {
  data() {
    return {
      imgStatusUrl: {
        '2': require('@/assets/images/refuse.png'),
        '3': require('@/assets/images/reject.png'),
        '4': require('@/assets/images/pass.png'),
        '5': require('@/assets/images/pay.png'),
        '6': require('@/assets/images/stop.png')
      }
    }
  }
}
