var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "peWrap",
        staticClass: "print-oli-wrap",
        attrs: { id: "contractPrint" },
      },
      [
        _vm.data.approvalStatus && _vm.data.approvalStatus !== "0"
          ? _c("img", {
              staticClass: "badge",
              attrs: {
                src: _vm.imgStatusUrl[_vm.data.approvalStatus],
                alt: "",
              },
            })
          : _vm._e(),
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "header-title" }, [_vm._v("合同审批")]),
          _c("div", { staticClass: "header-bottom" }, [
            _c("div", { staticClass: "header-bottom-item" }, [
              _vm._v("申请日期：" + _vm._s(_vm.data.createTime)),
            ]),
          ]),
        ]),
        _c("div", [
          _c(
            "table",
            { staticClass: "table" },
            [
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("申请人")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.createBy)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("合同编号")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.contractNo)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("签约日期")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.signingDate)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("我方单位名称")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.ourCompanyName)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [
                  _vm._v("我方联系人及联系方式"),
                ]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.ourContactsPhone)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("对方单位名称")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.partnerCompanyName)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [
                  _vm._v("对方负责人及联系方式"),
                ]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.partnerContactsPhone)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("合同类型")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.contractTypeFormat(_vm.data.contractType))),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("合同名称")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.contractName)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("合同约定生效方式")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(
                    _vm._s(_vm.effectiveFormat(_vm.data.contractEffectiveMode))
                  ),
                ]),
              ]),
              _vm.data.contractEffectiveDate
                ? _c("tr", { staticClass: "td" }, [
                    _c("td", { staticClass: "tl" }, [
                      _vm._v("合同约定生效日期"),
                    ]),
                    _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                      _vm._v(_vm._s(_vm.data.contractEffectiveDate)),
                    ]),
                  ])
                : _vm._e(),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("合同约定失效方式")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(
                    _vm._s(_vm.failureFormat(_vm.data.contractFailureMode))
                  ),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("合同失效日期")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.data.contractFailureDate)),
                ]),
              ]),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("金额类型")]),
                _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                  _vm._v(_vm._s(_vm.amountTypeFormat(_vm.data.amountType))),
                ]),
              ]),
              _vm.data.contractAmount
                ? _c("tr", { staticClass: "td" }, [
                    _c("td", { staticClass: "tl" }, [_vm._v("合同金额(元)")]),
                    _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                      _vm._v(_vm._s(_vm.data.contractAmount)),
                    ]),
                  ])
                : _vm._e(),
              _vm.data.invoiceType
                ? _c("tr", { staticClass: "td" }, [
                    _c("td", { staticClass: "tl" }, [_vm._v("发票类型")]),
                    _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                      _vm._v(
                        _vm._s(_vm.invoiceTypeFormat(_vm.data.invoiceType))
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.data.taxRate
                ? _c("tr", { staticClass: "td" }, [
                    _c("td", { staticClass: "tl" }, [_vm._v("税率")]),
                    _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                      _vm._v(_vm._s(_vm.data.taxRate)),
                    ]),
                  ])
                : _vm._e(),
              _c("tr", { staticClass: "td" }, [
                _c("td", { staticClass: "tl" }, [_vm._v("附件")]),
                _c(
                  "td",
                  { staticClass: "tr", attrs: { colspan: "5" } },
                  [
                    _vm._l(_vm.data.enclosureImageUrl, function (item) {
                      return _c("div", { key: item }, [_vm._v(_vm._s(item))])
                    }),
                    !_vm.data.enclosureImageUrl ||
                    _vm.data.enclosureImageUrl.length === 0
                      ? _c("div", [_vm._v("无")])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c("tr", { staticClass: "td purchase-approval" }, [
                _c(
                  "td",
                  {
                    staticClass: "tl",
                    attrs: {
                      rowspan:
                        _vm.data.approvalRecordVos &&
                        _vm.data.approvalRecordVos.length + 1,
                    },
                  },
                  [_vm._v("审批流程")]
                ),
                !_vm.data.approvalRecordVos
                  ? _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                      _vm._v(_vm._s(_vm.data.comment)),
                    ])
                  : _vm._e(),
              ]),
              _vm._l(_vm.data.approvalRecordVos, function (item, index) {
                return _c(
                  "tr",
                  { key: index, staticClass: "td purchase-approval" },
                  [
                    _c("td", { staticClass: "tr", attrs: { colspan: "5" } }, [
                      _c("div", { staticClass: "float-left" }, [
                        _vm._v(
                          _vm._s(item.approvalBy) +
                            " " +
                            _vm._s(_vm.approvalStatusFormat(item))
                        ),
                      ]),
                      _c("div", { staticClass: "float-right" }, [
                        _vm._v(_vm._s(item.approvalTime)),
                      ]),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "footer-cell" }, [
            _vm._v(
              "打印时间： " +
                _vm._s(_vm.parseDateTime(new Date(), "yyyy-MM-dd HH:mm:ss"))
            ),
          ]),
          _c("div", { staticClass: "footer-cell" }, [
            _vm._v("打印人： " + _vm._s(_vm.userInfo.userName)),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }