<!--
 * @Auth: linjituan
 * @Date: 2021-08-17 16:35:13
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-27 15:48:09
-->
<template>
  <div>
    <div class="print-oli-wrap" id="contractPrint" ref="peWrap">
      <img
        class="badge"
        v-if="data.approvalStatus && data.approvalStatus !== '0'"
        :src="imgStatusUrl[data.approvalStatus]"
        alt=""
      />
      <div class="header">
        <div class="header-title">合同审批</div>
        <div class="header-bottom">
          <!-- <div class="header-bottom-item">{{ data.deptName }}</div> -->
          <div class="header-bottom-item">申请日期：{{ data.createTime }}</div>
        </div>
      </div>
      <div>
        <table class="table">
          <tr class="td">
            <td class="tl">申请人</td>
            <td class="tr" colspan="5">{{ data.createBy }}</td>
          </tr>
          <tr class="td">
            <td class="tl">合同编号</td>
            <td class="tr" colspan="5">{{ data.contractNo }}</td>
          </tr>
          <tr class="td">
            <td class="tl">签约日期</td>
            <td class="tr" colspan="5">{{ data.signingDate }}</td>
          </tr>
          <tr class="td">
            <td class="tl">我方单位名称</td>
            <td class="tr" colspan="5">{{ data.ourCompanyName }}</td>
          </tr>
          <tr class="td">
            <td class="tl">我方联系人及联系方式</td>
            <td class="tr" colspan="5">{{ data.ourContactsPhone }}</td>
          </tr>
          <tr class="td">
            <td class="tl">对方单位名称</td>
            <td class="tr" colspan="5">{{ data.partnerCompanyName }}</td>
          </tr>
          <tr class="td">
            <td class="tl">对方负责人及联系方式</td>
            <td class="tr" colspan="5">{{ data.partnerContactsPhone }}</td>
          </tr>
          <tr class="td">
            <td class="tl">合同类型</td>
            <td class="tr" colspan="5">{{ contractTypeFormat(data.contractType) }}</td>
          </tr>
          <tr class="td">
            <td class="tl">合同名称</td>
            <td class="tr" colspan="5">{{ data.contractName }}</td>
          </tr>
          <tr class="td">
            <td class="tl">合同约定生效方式</td>
            <td class="tr" colspan="5">{{ effectiveFormat(data.contractEffectiveMode) }}</td>
          </tr>
          <tr class="td" v-if="data.contractEffectiveDate">
            <td class="tl">合同约定生效日期</td>
            <td class="tr" colspan="5">{{ data.contractEffectiveDate }}</td>
          </tr>
          <tr class="td">
            <td class="tl">合同约定失效方式</td>
            <td class="tr" colspan="5">{{ failureFormat(data.contractFailureMode) }}</td>
          </tr>
          <tr class="td">
            <td class="tl">合同失效日期</td>
            <td class="tr" colspan="5">{{ data.contractFailureDate }}</td>
          </tr>
          <tr class="td">
            <td class="tl">金额类型</td>
            <td class="tr" colspan="5">{{ amountTypeFormat(data.amountType) }}</td>
          </tr>
          <tr class="td" v-if="data.contractAmount">
            <td class="tl">合同金额(元)</td>
            <td class="tr" colspan="5">{{ data.contractAmount }}</td>
          </tr>
          <tr class="td" v-if="data.invoiceType">
            <td class="tl">发票类型</td>
            <td class="tr" colspan="5">{{ invoiceTypeFormat(data.invoiceType) }}</td>
          </tr>
          <tr class="td" v-if="data.taxRate">
            <td class="tl">税率</td>
            <td class="tr" colspan="5">{{ data.taxRate }}</td>
          </tr>
          <tr class="td">
            <td class="tl">附件</td>
            <td class="tr" colspan="5">
              <div v-for="item in data.enclosureImageUrl" :key="item">{{ item }}</div>
              <div v-if="!data.enclosureImageUrl || data.enclosureImageUrl.length === 0">无</div>
            </td>
          </tr>
          <tr class="td purchase-approval">
            <td :rowspan="data.approvalRecordVos && data.approvalRecordVos.length + 1" class="tl">审批流程</td>
            <td v-if="!data.approvalRecordVos" class="tr" colspan="5">{{ data.comment }}</td>
          </tr>
          <tr class="td purchase-approval" v-for="(item, index) in data.approvalRecordVos" :key="index">
            <td class="tr" colspan="5">
              <div class="float-left">{{ item.approvalBy }} {{ approvalStatusFormat(item) }}</div>
              <div class="float-right">{{ item.approvalTime }}</div>
            </td>
          </tr>
        </table>
      </div>
      <div class="footer">
        <div class="footer-cell">打印时间： {{ parseDateTime(new Date(), 'yyyy-MM-dd HH:mm:ss') }}</div>
        <div class="footer-cell">打印人： {{ userInfo.userName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import Watermark from '@/components/watermark/index.js'
import { parseDateTime } from '@/utils/ruoyi'
import { mapState } from 'vuex'
import base from './modules/base'
// import { listApprovalContract, delApprovalContract, exportApprovalContract } from '@/api/iot/approvalContract'
import { getApprovalProcess } from '@/api/iot/approvalProcessForm.js'
import { getApprovalContract } from '@/api/iot/approvalContract'

export default {
  props: {
    // data: {
    //   type: Object,
    //   default: () => ({})
    // },
    id: {
      type: String,
      default: ''
    },
    amountTypeOptions: {
      type: Array,
      default: () => []
    },
    invoiceTypeOptions: {
      type: Array,
      default: () => []
    },
    contractTypeOptions: {
      type: Array,
      default: () => []
    },
    effectiveOptions: {
      type: Array,
      default: () => []
    },
    failureOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.info
    })
  },
  mixins: [base],
  watch: {
    id(val) {
      console.log('processInstanceId', val)
      if (val) {
        this.getApprovalContract(val)
      }
    }
  },
  data() {
    return {
      urlListStr: ''
    }
  },
  mounted() {
    console.log('this.$refs.peWrap', this.$refs.peWrap.offsetTop)
    // Watermark.set({ str: this.userInfo.userName, el: this.$refs.peWrap })
  },
  methods: {
    async getDictData() {
      await this.getDicts('iot_expense_type').then(response => {
        this.expenseTypeOptions = response.data
      })
    },
    approvalStatusFormat(item) {
      switch (item.approvalAction) {
        case '2':
          return '拒绝'
        case '4':
          return '同意'
        case '6':
          return '暂停'
        default:
          return '流程中'
      }
    },
    parseDateTime,
    handleToApprove(instanceId) {
      getApprovalProcess(instanceId).then(response => {
        this.data.approvalRecordVos = response.data.approvalRecordVos
        this.$forceUpdate()
        this.$emit('readyPrint')
      })
    },
    getApprovalContract(id) {
      getApprovalContract(id).then(response => {
        this.data = response.data
        for (const key in this.data) {
          if (typeof this.data[key] === 'number') {
            this.data[key] = this.data[key].toString()
          }
        }
        this.handleToApprove(this.data.processInstanceId)
      })
    },
    amountTypeFormat(data) {
      return this.selectDictLabel(this.amountTypeOptions, data)
    },
    invoiceTypeFormat(data) {
      return this.selectDictLabel(this.invoiceTypeOptions, data)
    },
    contractTypeFormat(data) {
      return this.selectDictLabel(this.contractTypeOptions, data)
    },
    effectiveFormat(data) {
      return this.selectDictLabel(this.effectiveOptions, data)
    },
    failureFormat(data) {
      return this.selectDictLabel(this.failureOptions, data)
    }
  },
  beforeDestroy() {
    // Watermark.set('', '')
  }
}
</script>

<style scoped lang="less">
.print-oli-wrap {
  background: #fff;
  position: relative;
  padding: 40px;
  color: #000;
  .badge {
    position: absolute;
    width: 80px;
    height: 80px;
    right: 50px;
    top: 114px;
  }
  .header {
    margin-bottom: 10px;
    .header-title {
      font-size: 18px;
      text-align: center;
      color: #000;
      padding: 10px 0 20px;
    }
    .header-bottom {
      .header-bottom-item {
        display: inline-block;
        margin-right: 30px;
      }
    }
  }
  .table {
    width: 100%;
    border-bottom: 1px solid #333;
    .td {
      border-left: 1px solid #333;
      border-top: 1px solid #333;
      border-right: 1px solid #333;
      .tl {
        border-right: 1px solid #333;
        padding: 10px;
        white-space: nowrap;
      }
      .tr {
        padding: 10px;
      }
    }
    .purchase-detail {
      .tr {
        border-right: 1px solid #333;
        p {
          line-height: 1;
          margin: 0;
        }
      }
    }
    .purchase-approval {
      overflow: hidden;
      .tr {
        .float-left {
          float: left;
        }
        .float-right {
          float: right;
        }
      }
    }
  }
  .footer {
    margin-top: 20px;
    .footer-cell {
      display: inline-block;
      margin-right: 30px;
    }
  }
}
</style>
